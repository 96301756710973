
import { defineComponent, reactive, ref, getCurrentInstance } from 'vue'
import { useStore } from '@/store'
import { useCurrentInstance } from '@/utils/public-methods'
import Avatar from './avatar.vue'
import hhRequest from '@/service'

export default defineComponent({
  name: 'ChangePassword',
  components: { Avatar },
  setup() {
    const {
      state: { login },
      commit
    } = useStore()
    const user = login.user
    const form = ref()
    const state = reactive({
      password: '',
      checkPassword: '',
      code: ''
    })

    const { proxy } = useCurrentInstance()
    const match = ref(true)
    const rules = {
      code: [
        {
          required: true,
          message: proxy?.$filters.hh_t('verificationCodeNotEmpty'),
          trigger: 'blur'
        }
      ],
      password: [
        {
          required: true,
          message: proxy?.$filters.hh_t('passwordNotEmpty'),
          trigger: 'blur'
        },
        {
          pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/,
          message: proxy?.$filters.hh_t('passwordRule'),
          trigger: 'blur'
        }
      ],
      checkPassword: [
        {
          required: true,
          message: proxy?.$filters.hh_t('enterPasswordAgain'),
          trigger: 'blur'
        },
        {
          validator: passwordtoMatch,
          message: proxy?.$filters.hh_t('passwordNotMatch'),
          trigger: 'blur'
        }
      ]
    }
    function passwordtoMatch(rule: string, value: string) {
      if (value !== state.password) {
        return false
      } else {
        return true
      }
    }

    const countdown = ref(0)
    const sending = ref(false)
    const startCountdown = () => {
      countdown.value = 60
      const timer = setInterval(() => {
        countdown.value--
        if (countdown.value <= 0) {
          clearInterval(timer)
        }
      }, 1000)
    }

    const sendCode = async () => {
      sending.value = true
      try {
        await hhRequest.post({
          url: '/user/code',
          data: { email: user?.email }
        })
        startCountdown()
      } finally {
        sending.value = false
      }
    }

    const submit = async () => {
      try {
        if (form.value) {
          await form.value.validate()
          await hhRequest.post({ url: '/user/password', data: state })
          commit('login/logout')
          location.reload()
        }
      } catch (err) {
        return err
      }
    }

    return {
      submit,
      rules,
      user,
      state,
      countdown,
      sending,
      sendCode,
      form
    }
  }
})
